define("ember-sortable/system/scroll-container", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var ScrollContainer = function () {
    function ScrollContainer(element) {
      _classCallCheck(this, ScrollContainer);

      this.element = element;
      this.isWindow = element === document;
      if (this.isWindow) {
        this.top = this.scrollTop();
        this.left = this.scrollLeft();
        this.width = document.documentElement.clientWidth;
        this.height = document.documentElement.clientHeight;
        this.scrollWidth = document.documentElement.clientWidth;
        this.scrollHeight = document.documentElement.clientHeight;
      } else {
        var _element$getBoundingC = this.element.getBoundingClientRect(),
            top = _element$getBoundingC.top,
            left = _element$getBoundingC.left;

        this.top = top;
        this.left = left;
        this.width = getComputedStyle(this.element).width;
        this.height = getComputedStyle(this.element).height;
        this.scrollWidth = element.scrollWidth;
        this.scrollHeight = element.scrollHeight;
      }
      this.maxScrollTop = this.scrollHeight - this.height;
      this.maxScrollLeft = this.scrollWidth - this.width;
    }

    _createClass(ScrollContainer, [{
      key: "scrollTop",
      value: function scrollTop(value) {
        if (value) {
          value = Math.max(0, Math.min(this.maxScrollTop, value));
          this.element.scrollTop = value;
          if (this.isWindow) {
            this.top = value;
          }
          return value;
        }
        return this.element.scrollTop;
      }
    }, {
      key: "scrollLeft",
      value: function scrollLeft(value) {
        if (value) {
          value = Math.max(0, Math.min(this.maxScrollLeft, value));
          this.element.scrollLeft = value;
          if (this.isWindow) {
            this.left = value;
          }
          return value;
        }
        return this.element.scrollLeft;
      }
    }, {
      key: "$",
      value: function $(selector) {
        var element = this.element;
        if (selector) {
          return element.querySelector(selector);
        } else {
          return element;
        }
      }
    }, {
      key: "bottom",
      get: function get() {
        return this.top + this.height;
      }
    }, {
      key: "right",
      get: function get() {
        return this.left + this.width;
      }
    }]);

    return ScrollContainer;
  }();

  exports.default = ScrollContainer;
});