define('ember-sortable/system/scroll-parent', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (element) {
    var position = getComputedStyle(element).position;
    var excludeStaticParent = position === 'absolute';
    var scrollParent = getParentElements(element).filter(function (parent) {
      var parentElemStyles = getComputedStyle(parent);
      if (excludeStaticParent && parentElemStyles.position === 'static') {
        return false;
      }
      var overflow = parentElemStyles.overflow,
          overflowX = parentElemStyles.overflowX,
          overflowY = parentElemStyles.overflowY;

      return (/(auto|scroll)/.test(overflow + overflowX + overflowY)
      );
    })[0];

    if (!scrollParent || scrollParent === document.body) {
      scrollParent = document;
    }
    return position === 'fixed' || scrollParent;
  };

  function getParentElements(element) {
    var parentsArray = [];

    if (!element) {
      return parentsArray;
    }

    var currentParent = element.parentElement;

    while (currentParent !== null) {
      parentsArray.push(currentParent);
      currentParent = currentParent.parentElement;
    }
    return parentsArray;
  }
});